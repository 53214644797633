import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>
                        Acord de iOpos Acadèmia amb el centre mèdic Badamedic de Badalona.
                    </strong>
                </p>

                <p>
                    <strong>Amb una important novetat enguany:</strong>
                </p>
                <p>
                    En totes les revisions es realitzarà el monitoratge de la freqüència cardíaca i
                    l’obtenció d’un electrocardiograma.
                </p>

                <p>
                    El dia de la revisió, els nostres metges especialistes us formularan una sèrie
                    de preguntes per conèixer l’estat de salut del pacient.
                </p>

                <p>
                    <strong>
                        Posteriorment, es realitzaran una sèrie de proves i exploracions:
                    </strong>
                </p>
                <ul>
                    <li>IMC</li>
                    <li>Capacitat pulmonar</li>
                    <li>Freqüència cardíaca i presa de tensió arterial</li>
                    <li>Exploració bàsica de l’aparell locomotor</li>
                    <li>Electrocardiograma en repòs</li>
                </ul>

                <p>
                    Per accedir a la revisió, cal fer el pagament a través del link. Un cop feta la
                    compra, podeu demanar cita al telèfon <strong>618.86.41.79</strong>.
                </p>

                <p>
                    <strong>Documentació necessària el dia de la revisió:</strong>
                </p>
                <ul>
                    <li>DNI de la persona</li>
                    <li>L’escrit de les bases</li>
                    <li>La fulla del certificat mèdic oficial de l’estanc</li>
                </ul>

                <p>
                    <strong>Centre:</strong> Badamedic (Baldomer Solà núm. 80, Badalona)
                </p>

                <p>
                    <strong>Perquè a iOpos ens importa la teva salut!</strong>
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Cuida la teva salut i prepara't per a les oposicions amb la revisió mèdica més completa i accessible a Badamedic!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
